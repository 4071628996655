import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
// Google Analytics related
import {
    trackFirstPageLoadPerformanceGA,
    trackTimeToInteractionGA,
} from './utils/google_analytics';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

// Google Analytics initialize with tracker ID
trackTimeToInteractionGA();
trackFirstPageLoadPerformanceGA();

const renderRoot = () => {
    ReactDOM.render(
        <BrowserRouter basename={baseUrl}>
            <App />
        </BrowserRouter>,
        rootElement
    );

    unregisterServiceWorker();
};

renderRoot();
