import {
    QUESTION_INDEX,
    SUB_QUESTION_INDEX,
    YES_NO_OPTION,
    GENDER,
} from '../../../constants/enum';
import { isNil, isEmpty, get } from 'lodash';
import { SEP_QUESTIONS } from '../../../constants/verification';
import {
    validateEmail,
    validateDatePast,
    validatePhoneNumberLength,
    validateFirstLastName,
    validateDateFormat,
} from '../../../actions/Validation/utils/utils';
import { LANGUAGE_OPTIONS } from '../../../constants/options';
import AdditionalQuestionInputType from 'enums/AdditionalQuestionInputType';

const LANGUAGE_NONE = LANGUAGE_OPTIONS.indexOf('').toString();

const validateAdditionalQuestionDate = (values, errors, index, subIndex) => {
    const value =
        values.enrollment.enrolling.medicareAdvantageEligibility.questions[
            index
        ].additionalQuestions[subIndex].response;
    const error =
        errors.enrollment.enrolling.medicareAdvantageEligibility.questions[
            index
        ].additionalQuestions[subIndex];
    if (!value) {
        error.response = 'Please enter a date';
    } else if (!validateDateFormat(value)) {
        error.response = 'Please enter a valid date';
    } else if (
        SEP_QUESTIONS[index].additionalQuestions[subIndex].pastOnly &&
        !validateDatePast(value)
    ) {
        error.response = 'Please enter a date in the past';
    }
};

const validateAdditionalQuestion = (
    values,
    errors,
    index,
    subIndex,
    errorMessage
) => {
    const value =
        values.enrollment.enrolling.medicareAdvantageEligibility.questions[
            index
        ].additionalQuestions[subIndex].response;
    const error =
        errors.enrollment.enrolling.medicareAdvantageEligibility.questions[
            index
        ].additionalQuestions[subIndex];
    if (!value) {
        error.response = errorMessage;
    }
};

const validateMedicareAdvantageEligibility = (values, errors) => {
    SEP_QUESTIONS.forEach((question, questionIndex) => {
        if (
            values.enrollment.enrolling.medicareAdvantageEligibility.questions[
                questionIndex
            ].response &&
            question.additionalQuestions
        ) {
            SEP_QUESTIONS[questionIndex].additionalQuestions.forEach(
                (additionalQuestion, additionalQuestionIndex) => {
                    if (!additionalQuestion.required) return;
                    switch (additionalQuestion.type) {
                        case AdditionalQuestionInputType.Date:
                            validateAdditionalQuestionDate(
                                values,
                                errors,
                                questionIndex,
                                additionalQuestionIndex
                            );
                            break;
                        case AdditionalQuestionInputType.Select:
                            validateAdditionalQuestion(
                                values,
                                errors,
                                questionIndex,
                                additionalQuestionIndex,
                                'Please make a selection'
                            );
                            break;
                        case AdditionalQuestionInputType.FileUpload:
                            validateAdditionalQuestion(
                                values,
                                errors,
                                questionIndex,
                                additionalQuestionIndex,
                                'Please upload a file'
                            );
                            break;
                        default:
                            return;
                    }
                }
            );
        }
    });
};

const validateClientInfo = (values, errors) => {
    if (!values.enrollment.enrolling.clientInfo.proposedEffectiveDate) {
        errors.enrollment.enrolling.clientInfo.proposedEffectiveDate =
            'Please select effective date';
    }
    // Medicare Information Form
    if (!values.enrollment.enrolling.clientInfo.medicareID) {
        errors.enrollment.enrolling.clientInfo.medicareID = 'Required';
    }

    if (!values.enrollment.enrolling.clientInfo.dateOfBirth) {
        errors.enrollment.enrolling.clientInfo.dateOfBirth = 'Required';
    }
    if (
        !validateDateFormat(values.enrollment.enrolling.clientInfo.dateOfBirth)
    ) {
        errors.enrollment.enrolling.clientInfo.dateOfBirth =
            'Please enter a valid date';
    }
    if (!values.enrollment.enrolling.clientInfo.medicareEligibilityDatePartA) {
        errors.enrollment.enrolling.clientInfo.medicareEligibilityDatePartA =
            'Required';
    }
    if (
        !validateDateFormat(
            values.enrollment.enrolling.clientInfo.medicareEligibilityDatePartA
        )
    ) {
        errors.enrollment.enrolling.clientInfo.medicareEligibilityDatePartA =
            'Please enter a valid date';
    }
    if (!values.enrollment.enrolling.clientInfo.medicareEligibilityDatePartB) {
        errors.enrollment.enrolling.clientInfo.medicareEligibilityDatePartB =
            'Required';
    }
    if (
        !validateDateFormat(
            values.enrollment.enrolling.clientInfo.medicareEligibilityDatePartB
        )
    ) {
        errors.enrollment.enrolling.clientInfo.medicareEligibilityDatePartB =
            'Please enter a valid date';
    }
    if (!validateDatePast(values.enrollment.enrolling.clientInfo.dateOfBirth)) {
        errors.enrollment.enrolling.clientInfo.dateOfBirth =
            'Please enter a date in the past';
    }

    // Client Details
    if (!values.enrollment.enrolling.clientInfo.firstName) {
        errors.enrollment.enrolling.clientInfo.firstName = 'Required';
    }
    if (!values.enrollment.enrolling.clientInfo.lastName) {
        errors.enrollment.enrolling.clientInfo.lastName = 'Required';
    }
    if (
        !values.enrollment.enrolling.clientInfo.gender ||
        values.enrollment.enrolling.clientInfo.gender === GENDER.NONE.toString()
    ) {
        errors.enrollment.enrolling.clientInfo.gender = 'Required';
    }

    // Emergency Contact
    if (!validateEmail(values.enrollment.enrolling.clientInfo.emergencyEmail)) {
        errors.enrollment.enrolling.clientInfo.emergencyEmail =
            'Please enter a valid email address';
    }
    if (
        !validatePhoneNumberLength(
            values.enrollment.enrolling.clientInfo.emergencyPhone
        )
    ) {
        errors.enrollment.enrolling.clientInfo.emergencyPhone =
            'Please enter a valid 10 digit phone number';
    }

    // Permanent Address
    if (!values.enrollment.enrolling.clientInfo.permanentAddressLine1) {
        errors.enrollment.enrolling.clientInfo.permanentAddressLine1 =
            'Required';
    }
    if (!values.enrollment.enrolling.clientInfo.permanentCity) {
        errors.enrollment.enrolling.clientInfo.permanentCity = 'Required';
    }
    if (!values.enrollment.enrolling.clientInfo.permanentState) {
        errors.enrollment.enrolling.clientInfo.permanentState = 'Required';
    }
    if (!values.enrollment.enrolling.clientInfo.permanentZipCode) {
        errors.enrollment.enrolling.clientInfo.permanentZipCode = 'Required';
    }
    if (!values.enrollment.enrolling.clientInfo.addressMailingSame) {
        errors.enrollment.enrolling.clientInfo.addressMailingSame = 'Required';
    }
    // Mailing Address
    if (
        values.enrollment.enrolling.clientInfo.addressMailingSame ===
        YES_NO_OPTION['No'].toString()
    ) {
        if (!values.enrollment.enrolling.clientInfo.mailingAddressLine1) {
            errors.enrollment.enrolling.clientInfo.mailingAddressLine1 =
                'Required';
        }
        if (!values.enrollment.enrolling.clientInfo.mailingCity) {
            errors.enrollment.enrolling.clientInfo.mailingCity = 'Required';
        }
        if (!values.enrollment.enrolling.clientInfo.mailingState) {
            errors.enrollment.enrolling.clientInfo.mailingState = 'Required';
        }
        if (!values.enrollment.enrolling.clientInfo.mailingZipCode) {
            errors.enrollment.enrolling.clientInfo.mailingZipCode = 'Required';
        }
    }
    // Contact Information
    if (!values.enrollment.enrolling.clientInfo.primaryPhone) {
        errors.enrollment.enrolling.clientInfo.primaryPhone = 'Required';
    }
    if (
        !validatePhoneNumberLength(
            values.enrollment.enrolling.clientInfo.primaryPhone
        )
    ) {
        errors.enrollment.enrolling.clientInfo.primaryPhone =
            'Please enter a valid 10 digit phone number';
    }
    if (
        !validatePhoneNumberLength(
            values.enrollment.enrolling.clientInfo.cellPhone
        )
    ) {
        errors.enrollment.enrolling.clientInfo.cellPhone =
            'Please enter a valid 10 digit phone number';
    }
    if (
        !(
            values.enrollment.enrolling.clientInfo.receiveByMailEobPartC &&
            values.enrollment.enrolling.clientInfo.receiveByMailEobPartD &&
            values.enrollment.enrolling.clientInfo.receiveByMailAnoc
        )
    ) {
        if (!values.enrollment.enrolling.clientInfo.contactEmail) {
            errors.enrollment.enrolling.clientInfo.contactEmail =
                'Please enter email address';
        }
    }
    if (!validateEmail(values.enrollment.enrolling.clientInfo.contactEmail)) {
        errors.enrollment.enrolling.clientInfo.contactEmail =
            'Please enter a valid email address';
    }

    // Enroller Details
    if (!values.enrollment.enrolling.clientInfo.representativeFirstName) {
        errors.enrollment.enrolling.clientInfo.representativeFirstName =
            'Required';
    }
    if (!values.enrollment.enrolling.clientInfo.representativeLastName) {
        errors.enrollment.enrolling.clientInfo.representativeLastName =
            'Required';
    }
    if (!values.enrollment.enrolling.clientInfo.representativePhone) {
        errors.enrollment.enrolling.clientInfo.representativePhone = 'Required';
    }
    if (
        !validatePhoneNumberLength(
            values.enrollment.enrolling.clientInfo.representativePhone
        )
    ) {
        errors.enrollment.enrolling.clientInfo.representativePhone =
            'Please enter a valid 10 digit phone number';
    }
    if (!values.enrollment.enrolling.clientInfo.representativeAddressLine1) {
        errors.enrollment.enrolling.clientInfo.representativeAddressLine1 =
            'Required';
    }
    if (!values.enrollment.enrolling.clientInfo.representativeCity) {
        errors.enrollment.enrolling.clientInfo.representativeCity = 'Required';
    }
    if (!values.enrollment.enrolling.clientInfo.representativeState) {
        errors.enrollment.enrolling.clientInfo.representativeState = 'Required';
    }
    if (!values.enrollment.enrolling.clientInfo.representativeZipCode) {
        errors.enrollment.enrolling.clientInfo.representativeZipCode =
            'Required';
    }
};
const validateAdditionalInfo = (values, errors) => {
    if (
        isNil(
            values.enrollment.enrolling.additionalInfo.questions[
                QUESTION_INDEX.ENROLLMENT.ADDITIONAL_INFO.OTHER_COVERAGE
            ].response
        )
    ) {
        errors.enrollment.enrolling.additionalInfo.questions[
            QUESTION_INDEX.ENROLLMENT.ADDITIONAL_INFO.OTHER_COVERAGE
        ].response = 'Please answer this question';
    }
    if (
        isNil(
            values.enrollment.enrolling.additionalInfo.questions[
                QUESTION_INDEX.ENROLLMENT.ADDITIONAL_INFO.CHRONIC_CONDITION
            ].response
        )
    ) {
        errors.enrollment.enrolling.additionalInfo.questions[
            QUESTION_INDEX.ENROLLMENT.ADDITIONAL_INFO.CHRONIC_CONDITION
        ].response = 'Please answer this question';
    }
    if (
        isNil(
            values.enrollment.enrolling.additionalInfo.questions[
                QUESTION_INDEX.ENROLLMENT.ADDITIONAL_INFO.ENROLLED_MEDICAID
            ].response
        )
    ) {
        errors.enrollment.enrolling.additionalInfo.questions[
            QUESTION_INDEX.ENROLLMENT.ADDITIONAL_INFO.ENROLLED_MEDICAID
        ].response = 'Please answer this question';
    }
    if (
        isNil(
            values.enrollment.enrolling.additionalInfo.questions[
                QUESTION_INDEX.ENROLLMENT.ADDITIONAL_INFO.ELLIGIBLE_MEDICAID
            ].response
        )
    ) {
        errors.enrollment.enrolling.additionalInfo.questions[
            QUESTION_INDEX.ENROLLMENT.ADDITIONAL_INFO.ELLIGIBLE_MEDICAID
        ].response = 'Please answer this question';
    }

    // Coverage

    if (
        !values.enrollment.enrolling.additionalInfo.questions[
            QUESTION_INDEX.ENROLLMENT.ADDITIONAL_INFO.OTHER_COVERAGE
        ].additionalQuestions[SUB_QUESTION_INDEX.OTHER_COVERAGE.COVERAGE_NAME]
            .response
    ) {
        errors.enrollment.enrolling.additionalInfo.questions[
            QUESTION_INDEX.ENROLLMENT.ADDITIONAL_INFO.OTHER_COVERAGE
        ].additionalQuestions[
            SUB_QUESTION_INDEX.OTHER_COVERAGE.COVERAGE_NAME
        ].response = 'Please enter coverage name';
    }
    if (
        !values.enrollment.enrolling.additionalInfo.questions[
            QUESTION_INDEX.ENROLLMENT.ADDITIONAL_INFO.OTHER_COVERAGE
        ].additionalQuestions[SUB_QUESTION_INDEX.OTHER_COVERAGE.COVERAGE_GROUP]
            .response
    ) {
        errors.enrollment.enrolling.additionalInfo.questions[
            QUESTION_INDEX.ENROLLMENT.ADDITIONAL_INFO.OTHER_COVERAGE
        ].additionalQuestions[
            SUB_QUESTION_INDEX.OTHER_COVERAGE.COVERAGE_GROUP
        ].response = 'Please enter coverage group number';
    }
    if (
        !values.enrollment.enrolling.additionalInfo.questions[
            QUESTION_INDEX.ENROLLMENT.ADDITIONAL_INFO.OTHER_COVERAGE
        ].additionalQuestions[SUB_QUESTION_INDEX.OTHER_COVERAGE.COVERAGE_ID]
            .response
    ) {
        errors.enrollment.enrolling.additionalInfo.questions[
            QUESTION_INDEX.ENROLLMENT.ADDITIONAL_INFO.OTHER_COVERAGE
        ].additionalQuestions[
            SUB_QUESTION_INDEX.OTHER_COVERAGE.COVERAGE_ID
        ].response = 'Please enter coverage member number';
    }

    // State Medicaid

    if (
        !values.enrollment.enrolling.additionalInfo.questions[
            QUESTION_INDEX.ENROLLMENT.ADDITIONAL_INFO.ENROLLED_MEDICAID
        ].additionalQuestions[SUB_QUESTION_INDEX.ENROLLED_MEDICAID.MEDICAID_NUM]
            .response
    ) {
        errors.enrollment.enrolling.additionalInfo.questions[
            QUESTION_INDEX.ENROLLMENT.ADDITIONAL_INFO.ENROLLED_MEDICAID
        ].additionalQuestions[
            SUB_QUESTION_INDEX.ENROLLED_MEDICAID.MEDICAID_NUM
        ].response = 'Please enter State Medicaid number';
    }
    // Payment Option
    if (!values.enrollment.enrolling.additionalInfo.premiumPaymentOption) {
        errors.enrollment.enrolling.additionalInfo.premiumPaymentOption =
            'Please select a payment method';
    }
};
const validatePrequalSNP = (values, errors) => {
    if (
        isNil(
            values.enrollment.enrolling.prequalSNP.questions[
                QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION.HOMEBOUND
            ].response
        )
    ) {
        errors.enrollment.enrolling.prequalSNP.questions[
            QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION.HOMEBOUND
        ].response = 'This field is required';
    }
    // Diabetes
    if (
        isNil(
            values.enrollment.enrolling.prequalSNP.questions[
                QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION.DIABETES_1
            ].response
        )
    ) {
        errors.enrollment.enrolling.prequalSNP.questions[
            QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION.DIABETES_1
        ].response = 'This field is required';
    }
    if (
        isNil(
            values.enrollment.enrolling.prequalSNP.questions[
                QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION.DIABETES_2
            ].response
        )
    ) {
        errors.enrollment.enrolling.prequalSNP.questions[
            QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION.DIABETES_2
        ].response = 'This field is required';
    }
    // Cardiovascular
    if (
        isNil(
            values.enrollment.enrolling.prequalSNP.questions[
                QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION.CARDIO_1
            ].response
        )
    ) {
        errors.enrollment.enrolling.prequalSNP.questions[
            QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION.CARDIO_1
        ].response = 'This field is required';
    }
    if (
        isNil(
            values.enrollment.enrolling.prequalSNP.questions[
                QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION.CARDIO_2
            ].response
        )
    ) {
        errors.enrollment.enrolling.prequalSNP.questions[
            QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION.CARDIO_2
        ].response = 'This field is required';
    }
    // Chronic Heart
    if (
        isNil(
            values.enrollment.enrolling.prequalSNP.questions[
                QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION.CHRONIC_1
            ].response
        )
    ) {
        errors.enrollment.enrolling.prequalSNP.questions[
            QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION.CHRONIC_1
        ].response = 'This field is required';
    }

    if (
        isNil(
            values.enrollment.enrolling.prequalSNP.questions[
                QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION.CHRONIC_2
            ].response
        )
    ) {
        errors.enrollment.enrolling.prequalSNP.questions[
            QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION.CHRONIC_2
        ].response = 'This field is required';
    }
    // ESRD
    if (
        isNil(
            values.enrollment.enrolling.prequalSNP.questions[
                QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION.ESRD_1
            ].response
        )
    ) {
        errors.enrollment.enrolling.prequalSNP.questions[
            QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION.ESRD_1
        ].response = 'This field is required';
    }
    if (
        isNil(
            values.enrollment.enrolling.prequalSNP.questions[
                QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION.ESRD_2
            ].response
        )
    ) {
        errors.enrollment.enrolling.prequalSNP.questions[
            QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION.ESRD_2
        ].response = 'This field is required';
    }

    // Physician Info
    if (
        !values.enrollment.enrolling.prequalSNP.specialistName ||
        !values.enrollment.enrolling.prequalSNP.specialistPhone
    ) {
        if (!values.enrollment.enrolling.prequalSNP.physicianName) {
            errors.enrollment.enrolling.prequalSNP.physicianName =
                'This field is required';
        }
        if (!values.enrollment.enrolling.prequalSNP.physicianPhone) {
            errors.enrollment.enrolling.prequalSNP.physicianPhone =
                'This field is required';
        }
    }
    if (
        !validatePhoneNumberLength(
            values.enrollment.enrolling.prequalSNP.physicianPhone
        )
    ) {
        errors.enrollment.enrolling.prequalSNP.physicianPhone =
            'Please enter a valid 10 digit phone number';
    }

    if (
        !values.enrollment.enrolling.prequalSNP.physicianName ||
        !values.enrollment.enrolling.prequalSNP.physicianPhone
    ) {
        if (!values.enrollment.enrolling.prequalSNP.specialistName) {
            errors.enrollment.enrolling.prequalSNP.specialistName =
                'This field is required';
        }
        if (!values.enrollment.enrolling.prequalSNP.specialistPhone) {
            errors.enrollment.enrolling.prequalSNP.specialistPhone =
                'This field is required';
        }
        if (
            !validatePhoneNumberLength(
                values.enrollment.enrolling.prequalSNP.specialistPhone
            )
        ) {
            errors.enrollment.enrolling.prequalSNP.specialistPhone =
                'Please enter a valid 10 digit phone number';
        }
    }
    if (!values.enrollment.enrolling.prequalSNP.listMedications) {
        errors.enrollment.enrolling.prequalSNP.listMedications =
            'This field is required';
    }
    if (!values.enrollment.enrolling.prequalSNP.authorizeAHPRequestRecords) {
        errors.enrollment.enrolling.prequalSNP.authorizeAHPRequestRecords =
            'This field is required';
    }
    if (!values.enrollment.enrolling.prequalSNP.beneficiarySignature) {
        errors.enrollment.enrolling.prequalSNP.beneficiarySignature =
            'This field is required';
    }
    if (
        !validateFirstLastName(
            values.enrollment.enrolling.prequalSNP.beneficiarySignature
        )
    ) {
        errors.enrollment.enrolling.prequalSNP.beneficiarySignature =
            'Please enter first and last name';
    }
    if (!values.enrollment.enrolling.prequalSNP.beneficiarySignatureDate) {
        errors.enrollment.enrolling.prequalSNP.beneficiarySignatureDate =
            'Please enter valid date';
    }
    if (!values.enrollment.enrolling.prequalSNP.agentSignature) {
        errors.enrollment.enrolling.prequalSNP.agentSignature =
            'Please enter signature';
    }
    if (!values.enrollment.enrolling.prequalSNP.agentSignatureDate) {
        errors.enrollment.enrolling.prequalSNP.agentSignatureDate =
            'Please enter valid date';
    }
};
const validateEnrolling = (values, errors) => {
    validateMedicareAdvantageEligibility(values, errors);
    validateClientInfo(values, errors);
    validateAdditionalInfo(values, errors);
    validatePrequalSNP(values, errors);
};
export default validateEnrolling;
