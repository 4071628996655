import { RELATIONSHIP, GENDER, RECEIPT_PREF, DISASTER_ENUM } from './enum';

const RELATIONSHIP_OPTIONS = [
    'Child',
    'Conservator',
    "Doctor's Office",
    'Employee',
    'Friend',
    'Caregiver',
    'Health Plan',
    'Medical Group',
    'Member',
    'Neighbor',
    'N/A',
    'Sales Rep',
    'Parent',
    'Power of Attorney',
    'Relative',
    'Spouse',
    'Specialist',
    'Other',
];
const RELATIONSHIP_MAP = {
    Child: RELATIONSHIP.CHILD,
    Conservator: RELATIONSHIP.CONSERVATOR,
    "Doctor's Office": RELATIONSHIP.DOCTORSOFFICE,
    Employee: RELATIONSHIP.EMPLOYEE,
    Friend: RELATIONSHIP.FRIEND,
    Caregiver: RELATIONSHIP.CAREGIVER,
    'Health Plan': RELATIONSHIP.HEALTHPLAN,
    'Medical Group': RELATIONSHIP.MEDICALGROUP,
    Member: RELATIONSHIP.MEMBER,
    Neighbor: RELATIONSHIP.NEIGHBOR,
    'N/A': RELATIONSHIP.NOTAPPLICABLE,
    'Sales Rep': RELATIONSHIP.SALESREP,
    Parent: RELATIONSHIP.PARENT,
    'Power of Attorney': RELATIONSHIP.POWEROFATTORNEY,
    Relative: RELATIONSHIP.RELATIVE,
    Spouse: RELATIONSHIP.SPOUSE,
    Specialist: RELATIONSHIP.SPECIALIST,
    Other: RELATIONSHIP.OTHER,
};
const GENDER_MAP = {
    Male: GENDER.MALE,
    Female: GENDER.FEMALE,
    Unknown: GENDER.UNKNOWN,
};
const CONTACT_OPTIONS = [
    'Community Event',
    'Phone',
    'Referral',
    'Email',
    'Same Day Appointment',
    'Walk in',
];
const LANGUAGE_OPTIONS = [
    'English',
    'Spanish',
    'Armenian',
    'Arabic',
    'Chinese',
    'Cambodian',
    'Cantonese',
    'Dutch',
    'French',
    'Greek',
    'Hindi',
    'Italian',
    'Japanese',
    'Korean',
    'Mandarin',
    'Portuguese',
    'Russian',
    'Samoan',
    'Tagalog',
    'Vietnamese',
    'Other',
];
const GENDER_OPTIONS = ['Male', 'Female', 'Unknown'];
const RACE_OPTIONS = [
    { label: 'American Indian or Alaska Native', value: 300 },
    { label: 'Asian Indian', value: 401 },
    { label: 'Black or African American', value: 201 },
    { label: 'Chinese', value: 411 },
    { label: 'Filipino', value: 421 },
    { label: 'Guamanian or Chamorro', value: 521 },
    { label: 'Japanese', value: 431 },
    { label: 'Korean', value: 441 },
    { label: 'Native Hawaiian', value: 501 },
    { label: 'Other Asian', value: 499 },
    { label: 'Other Pacific Islander', value: 599 },
    { label: 'Samoan', value: 511 },
    { label: 'White', value: 101 },
    { label: 'I choose not to answer', value: 999 },
];
const ETHNICITY_OPTIONS = [
    { label: 'No, not of Hispanic, Latino/a, or Spanish origin', value: 'NA' },
    { label: 'Yes, Mexican, Mexican American, Chicano/a', value: 'MX' },
    { label: 'Yes, Puerto Rican', value: 'PR' },
    { label: 'Yes, Cuban', value: 'CUB' },
    {
        label: 'Yes, another Hispanic, Latino/a, or Spanish origin',
        value: 'OTH',
    },
    { label: 'I choose not to answer', value: 'NR' },
];

const ACCESSIBLE_FORMATS = [
    { label: 'Large Print', value: RECEIPT_PREF.LARGE_PRINT },
    { label: 'Braille', value: RECEIPT_PREF.BRAILLE },
    { label: 'Audio Files', value: RECEIPT_PREF.AUDIO_CD },
];

const DISASTER_TYPES = [
    { label: 'Drought', value: DISASTER_ENUM.DROUGHT },
    { label: 'Flood', value: DISASTER_ENUM.FLOOD },
    { label: 'Fire', value: DISASTER_ENUM.FIRE },
    { label: 'Other', value: DISASTER_ENUM.OTHER },
];

export {
    RELATIONSHIP_OPTIONS,
    RELATIONSHIP_MAP,
    GENDER_MAP,
    CONTACT_OPTIONS,
    LANGUAGE_OPTIONS,
    GENDER_OPTIONS,
    RACE_OPTIONS,
    ETHNICITY_OPTIONS,
    ACCESSIBLE_FORMATS,
    DISASTER_TYPES,
};
