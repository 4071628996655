import AdditionalQuestionsInputType from '../../../enums/AdditionalQuestionInputType';
import {
    EligibilityQuestion,
    AdditionalQuestion,
} from '../../../types/EligibilityQuestion';
import { compact } from 'lodash';
import { ACCESSIBLE_FORMATS, DISASTER_TYPES } from '../constants/options';

const SEP_QUESTIONS_TEXT: string[] = [
    'I am new to Medicare.',
    'I am enrolled in a Medicare Advantage plan and want to make a change during the Medicare Advantage Open Enrollment Period (MA OEP).',
    'I recently moved outside of the service area for my current plan or I recently moved, and this plan is a new option for me.',
    'I recently was released from incarceration.',
    'I recently returned to the United States after living permanently outside of the U.S.',
    'I recently obtained lawful presence status in the United States.',
    'I recently had a change in my Medicaid (newly got Medicaid, had a change in level of Medicaid assistance, or lost Medicaid).',
    'I recently had a change in my Extra Help paying for Medicare prescription drug coverage (newly got Extra Help, had a change in the level of Extra Help, or lost Extra Help).',
    "I have both Medicare and Medicaid (or my state helps pay for my Medicare premiums) or I get Extra Help paying for my Medicare prescription drug coverage, but haven't had a change.",
    'I am moving into, live in, or recently moved out of a Long-Term Care Facility (for example, a nursing home or long term care facility).',
    'I recently left a PACE program.',
    "I recently involuntarily lost my creditable prescription drug coverage (coverage as good as Medicare's).",
    'I am leaving employer or union coverage.',
    'I belong to a pharmacy assistance program provided by my state.',
    'My plan is ending its contract with Medicare, or Medicare is ending its contract with my plan.',
    'I was enrolled in a plan by Medicare (or my state) and I want to choose a different plan.',
    'I was enrolled in a Special Needs Plan(SNP) but I have lost the special needs qualification required to be in that plan.',
    'I was affected by a weather-related emergency or major disaster, as declared by the Federal Emergency Management Agency (F.E.M.A). One of the other statements here applied to me, but I was unable to make my enrollment because of the natural disaster.',
    'My plan terminated its contract with providers in their network and does not have contracted providers (provider disruption - CMS / health plan issued letter)',
    'I received my Medicare Entitlements Retroactively.',
    'I am enrolling in a Plan Performance Rating of Five (5) Stars.',
    'I was not provided Requested Materials in Accessible Formats to Make Enrollment Decisions.',
    'My plan had a Significant Change in Provider Network.',
    'I am eligible for enrollment into a Chronic Care SNP, or I was found ineligible for a Chronic Care SNP.',
    'I enrolled in Medicare premium Part A or Part B (I already have Medicare Part A) using an Exceptional Condition SEP.',
];

const ADDITIONAL_QUESTIONS_MAP: {
    questionNumber: number;
    additionalQuestions: AdditionalQuestion[];
}[] = [
    {
        questionNumber: 3,
        additionalQuestions: [
            {
                required: true,
                type: AdditionalQuestionsInputType.Date,
                label: 'I moved on',
                pastOnly: true,
            },
        ],
    },
    {
        questionNumber: 4,
        additionalQuestions: [
            {
                required: true,
                type: AdditionalQuestionsInputType.Date,
                label: 'I was released on',
                pastOnly: true,
            },
        ],
    },
    {
        questionNumber: 5,
        additionalQuestions: [
            {
                required: true,
                type: AdditionalQuestionsInputType.Date,
                label: 'I returned to the U.S. on',
                pastOnly: true,
            },
        ],
    },
    {
        questionNumber: 6,
        additionalQuestions: [
            {
                required: true,
                type: AdditionalQuestionsInputType.Date,
                label: 'I got this status on',
                pastOnly: true,
            },
        ],
    },
    {
        questionNumber: 7,
        additionalQuestions: [
            {
                required: true,
                type: AdditionalQuestionsInputType.Date,
                label: 'On',
                pastOnly: true,
            },
        ],
    },
    {
        questionNumber: 8,
        additionalQuestions: [
            {
                required: true,
                type: AdditionalQuestionsInputType.Date,
                label: 'On',
                pastOnly: true,
            },
        ],
    },
    {
        questionNumber: 10,
        additionalQuestions: [
            {
                required: true,
                type: AdditionalQuestionsInputType.Date,
                label: 'I moved/will move into/out of the facility on',
                pastOnly: false,
            },
        ],
    },
    {
        questionNumber: 11,
        additionalQuestions: [
            {
                required: true,
                type: AdditionalQuestionsInputType.Date,
                label: 'On',
                pastOnly: true,
            },
        ],
    },
    {
        questionNumber: 12,
        additionalQuestions: [
            {
                required: true,
                type: AdditionalQuestionsInputType.Date,
                label: 'I lost my drug coverage on',
                pastOnly: true,
            },
        ],
    },
    {
        questionNumber: 13,
        additionalQuestions: [
            {
                required: true,
                type: AdditionalQuestionsInputType.Date,
                label: 'On',
                pastOnly: false,
            },
        ],
    },
    {
        questionNumber: 14,
        additionalQuestions: [
            {
                required: false,
                type: AdditionalQuestionsInputType.Date,
                label: 'On',
                pastOnly: false,
            },
        ],
    },
    {
        questionNumber: 16,
        additionalQuestions: [
            {
                required: true,
                type: AdditionalQuestionsInputType.Date,
                label: 'My enrollment in that plan started on',
                pastOnly: true,
            },
        ],
    },
    {
        questionNumber: 17,
        additionalQuestions: [
            {
                required: true,
                type: AdditionalQuestionsInputType.Date,
                label: 'I was disenrolled from the SNP on',
                pastOnly: true,
            },
        ],
    },
    {
        questionNumber: 18,
        additionalQuestions: [
            {
                required: false,
                type: AdditionalQuestionsInputType.Select,
                label: 'I was affected by',
                options: Object.assign(
                    {},
                    ...DISASTER_TYPES.map(({ label, value }) => ({
                        [label]: value,
                    }))
                ),
            },
            {
                required: false,
                type: AdditionalQuestionsInputType.Select,
                label: 'Missed SEP',
                options: Object.assign(
                    {},
                    ...compact(
                        SEP_QUESTIONS_TEXT.map(
                            (question, index) =>
                                index + 1 !== 18 && {
                                    [question]: index + 1, // starts from 1 to align with question number
                                }
                        )
                    )
                ),
            },
        ],
    },
    {
        questionNumber: 20,
        additionalQuestions: [
            {
                required: false,
                type: AdditionalQuestionsInputType.FileUpload,
                label: 'SSI Award Letter',
                fileUploadInfo: {
                    documentType: 'SEPSSI',
                    uploadText: 'Upload SSI Award Letter',
                    headerText: 'SSI Award Letter',
                },
            },
        ],
    },
    {
        questionNumber: 22,
        additionalQuestions: [
            {
                required: false,
                type: AdditionalQuestionsInputType.Select,
                label: 'I was not provided Requested Materials in the following format',
                options: Object.assign(
                    {},
                    ...ACCESSIBLE_FORMATS.map(({ label, value }) => ({
                        [label]: value,
                    }))
                ),
            },
            {
                required: false,
                type: AdditionalQuestionsInputType.Select,
                label: 'Missed SEP',
                options: Object.assign(
                    {},
                    ...compact(
                        SEP_QUESTIONS_TEXT.map(
                            (question, index) =>
                                index + 1 !== 22 && {
                                    [question]: index + 1, // starts from 1 to align with question number
                                }
                        )
                    )
                ),
            },
            {
                required: false,
                type: AdditionalQuestionsInputType.Date,
                label: 'I requested Materials on',
                pastOnly: true,
            },
        ],
    },
    {
        questionNumber: 23,
        additionalQuestions: [
            {
                required: false,
                type: AdditionalQuestionsInputType.Date,
                label: 'On',
                pastOnly: true,
            },
            {
                required: false,
                type: AdditionalQuestionsInputType.FileUpload,
                label: 'Change/Termination letter',
                fileUploadInfo: {
                    documentType: 'SEPChange',
                    uploadText: 'Upload Change/Termination Letter',
                    headerText: 'Change/Termination Letter',
                },
            },
        ],
    },
];

const SEP_QUESTIONS: EligibilityQuestion[] = SEP_QUESTIONS_TEXT.map(
    (question, index) => {
        const additionalQuestions = ADDITIONAL_QUESTIONS_MAP.find(
            (additionalQuestionsMap) =>
                additionalQuestionsMap.questionNumber === index + 1
        )?.additionalQuestions;
        const eligibilityQuestion = {
            text: question,
        };
        if (additionalQuestions) {
            eligibilityQuestion['additionalQuestions'] = additionalQuestions;
        }
        return eligibilityQuestion;
    }
);

export { SEP_QUESTIONS };
