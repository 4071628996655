import { get, isEmpty, partial } from 'lodash';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { PAGES } from '../constants/enum';

const PAGES_TO_NAME = {
    1: 'FIND_PLAN',
    2: 'SELECT_PLAN',
    3: 'OPTIONAL_BENEFITS_PLAN',
    4: 'SUMMARY',
    5: 'ENROLLMENT',
    6: 'CONFIRMATION',
};
const SUBPAGES_TO_NAME = {
    // SELECT PLANS PAGE
    2: {
        1: 'SELECT_PLAN',
        2: 'COMPARE_PLAN',
    },
    // ENROLLMENT PAGE
    5: {
        1: 'APPLICANT_INFO',
        2: 'CONFIRM_ELIGIBILITY',
        3: 'CHOOSE_DOCTOR',
        4: 'ADDITIONAL_INFO',
        5: 'SNP_PREQUALIFICATION',
        6: 'REVIEW',
    },
};

const getPathName = (page, subPage) => {
    const hostUrl = `https://${window.location.host}/`;
    const pageName = PAGES_TO_NAME[page];
    const subPageName = get(SUBPAGES_TO_NAME, `${page}.${subPage}`);
    if (!pageName) {
        return null;
    }
    if (subPageName) {
        return [`${hostUrl}${pageName}/${subPageName}`, pageName, subPageName];
    }
    return [`${hostUrl}${pageName}`, pageName, subPageName];
};

const useGAPageTracker = (page, subPage, enrollment, confirmationNo) => {
    useEffect(() => {
        const [pathName, pageName, subPageName] = getPathName(page, subPage);
        if (pathName) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'page_view',
                    page_location: pathName,
                    page_title: subPageName
                        ? `${pageName}-${subPageName}`
                        : `${pageName}`,
                },
            });
        }
    }, [page, subPage]);

    useEffect(() => {
        if (page === PAGES.CONFIRMATION && !isEmpty(confirmationNo)) {
            const fromEnroll = partial(get, enrollment);

            const transaction_id = fromEnroll('applicationId');
            const item = {
                item_id: `${fromEnroll(
                    'selectPlan.planSummary.contractCode'
                )}_${fromEnroll('selectPlan.planSummary.pbp')}`,
                item_name: fromEnroll('selectPlan.planSummary.planName'),
                item_category: `Enrollment_Year_${fromEnroll(
                    'findPlan.coverageYear'
                )}`,
                price: parseFloat(
                    fromEnroll('selectPlan.planSummary.premiumAmount', 0)
                ),
            };
            const optionalItem = fromEnroll('selectPlan.optionalPlanName')
                ? {
                      item_id: fromEnroll('selectPlan.optionalPlanName'),
                      item_name: fromEnroll('selectPlan.optionalPlanName'),
                      price: parseFloat(
                          fromEnroll(
                              'selectPlan.optionalPlanSummary.premiumAmount',
                              0
                          )
                      ),
                  }
                : null;
            TagManager.dataLayer({
                dataLayer: {
                    event: 'purchase',
                    transaction_id,
                    items: optionalItem ? [item, optionalItem] : [item],
                },
            });
        }
    }, [page, enrollment, confirmationNo]);
};

export { useGAPageTracker };
