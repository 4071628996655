import ttiPolyfill from 'tti-polyfill';
import TagManager from 'react-gtm-module';

const ALLOWED_ENVS = ['PROD'];

const initializeGTM = (GoogleTagManagerTagId) => {
    const tagManagerArgs = {
        gtmId: GoogleTagManagerTagId,
        events: {
            page_view: 'Page View',
            purchase: 'Purchase',
            initialLoadPerformance: 'Initial Load Performance',
        },
    };
    TagManager.initialize(tagManagerArgs);
    const injectedTag = document.getElementsByTagName('noscript')[0];
    injectedTag.setAttribute('role', 'banner');
    injectedTag.setAttribute('aria-label', 'Google Tag');
    injectedTag.setAttribute('aria-hidden', 'true');
};
const getGTMTag = () => {
    fetch('/api/ConfigSettings/GetAllResourceURLs')
        .then((resp) => resp.json())
        .then(({ GoogleTagManagerTagId, Environment }) => {
            if (ALLOWED_ENVS.includes(Environment)) {
                initializeGTM(GoogleTagManagerTagId);
            }
        });
};
getGTMTag();

const trackTimeToInteractionGA = () => {
    ttiPolyfill.getFirstConsistentlyInteractive().then((tti) => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'initialLoadPerformance',
                time_to_interactive: tti,
            },
        });
    });
};

const trackFirstPageLoadPerformanceGA = () => {
    const callback = (list) => {
        list.getEntries().forEach(
            ({ requestStart, responseStart, responseEnd }) => {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'initialLoadPerformance',
                        initial_load_page_server_latency:
                            responseStart - requestStart,
                    },
                });
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'initialLoadPerformance',
                        initial_load_page_download_time:
                            responseEnd - responseStart,
                    },
                });
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'initialLoadPerformance',
                        initial_load_page_total_load_time:
                            responseEnd - requestStart,
                    },
                });
            }
        );
    };

    var observer = new PerformanceObserver(callback);
    observer.observe({ entryTypes: ['navigation'] });
};

export { trackFirstPageLoadPerformanceGA, trackTimeToInteractionGA };
