import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import validate from '../../../actions/validate';
import { translateBool } from '../../../../util/translation';
import {
    DIABETES_SURVEY,
    CARDIOVASCULAR_SURVEY,
    HEART_FAILURE_SURVEY,
    ESRD_SURVEY,
} from '../../../../constants/prequalification';
import { QUESTION_INDEX } from '../../../../constants/enum';

const SNP_PRE = QUESTION_INDEX.ENROLLMENT.SNP_PREQUALIFICATION;

let ReviewSNP = (props) => {
    const { enrollment } = props;
    return (
        <>
            <div className="row">
                <div className="col-md-5">
                    <ul className="no-bullet list-align-left">
                        <li>
                            1. Do you consider yourself to be homebound?{' '}
                            <div>
                                <strong>
                                    {translateBool(
                                        enrollment.enrolling.prequalSNP
                                            .questions[SNP_PRE.HOMEBOUND]
                                            .response
                                    )}
                                </strong>
                            </div>
                        </li>
                        <li className="pt-3">DIABETES</li>
                        {Object.keys(DIABETES_SURVEY).map((keyValue, i) => {
                            return (
                                <li key={keyValue}>
                                    {i + 1}. {DIABETES_SURVEY[keyValue]}
                                    <div>
                                        <strong>
                                            {translateBool(
                                                enrollment.enrolling.prequalSNP
                                                    .questions[
                                                    i + SNP_PRE.DIABETES_1
                                                ].response
                                            )}
                                        </strong>
                                    </div>
                                </li>
                            );
                        })}
                        <li className="pt-3">CARDIOVASCULAR</li>
                        <li>
                            1. {CARDIOVASCULAR_SURVEY['cardiovascular1']}
                            <div>
                                <strong>
                                    {translateBool(
                                        enrollment.enrolling.prequalSNP
                                            .questions[SNP_PRE.CARDIO_1]
                                            .response
                                    )}
                                </strong>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="col-md-5">
                    <ul className="no-bullet list-align-left">
                        <li>
                            2. {CARDIOVASCULAR_SURVEY['cardiovascular2']}
                            <div>
                                <strong>
                                    {translateBool(
                                        enrollment.enrolling.prequalSNP
                                            .questions[SNP_PRE.CARDIO_2]
                                            .response
                                    )}
                                </strong>
                            </div>
                        </li>
                        <li className="pt-3">HEART</li>
                        {Object.keys(HEART_FAILURE_SURVEY).map(
                            (keyValue, i) => {
                                return (
                                    <li key={keyValue}>
                                        {i + 1}.{' '}
                                        {HEART_FAILURE_SURVEY[keyValue]}
                                        <div>
                                            <strong>
                                                {translateBool(
                                                    enrollment.enrolling
                                                        .prequalSNP.questions[
                                                        i + SNP_PRE.CHRONIC_1
                                                    ].response
                                                )}
                                            </strong>
                                        </div>
                                    </li>
                                );
                            }
                        )}
                        <li className="pt-3">ESRD</li>
                        {Object.keys(ESRD_SURVEY).map((keyValue, i) => {
                            return (
                                <li key={keyValue}>
                                    {i + 1}. {ESRD_SURVEY[keyValue]}
                                    <div>
                                        <strong>
                                            {translateBool(
                                                enrollment.enrolling.prequalSNP
                                                    .questions[
                                                    i + SNP_PRE.ESRD_1
                                                ].response
                                            )}
                                        </strong>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            <h5 className="review-sub-title">SNP Physician Information</h5>
            <div className="row">
                <div className="col-md-5">
                    <ul className="no-bullet list-align-left">
                        <li>
                            Physician Name:{' '}
                            <strong>
                                {
                                    enrollment.enrolling.prequalSNP[
                                        'physicianName'
                                    ]
                                }
                            </strong>
                        </li>
                        <li>
                            Phone Number:{' '}
                            <strong>
                                {
                                    enrollment.enrolling.prequalSNP[
                                        'physicianPhone'
                                    ]
                                }
                            </strong>
                        </li>
                        <li className="long-text">
                            Medication(s):{' '}
                            <strong>
                                {
                                    enrollment.enrolling.prequalSNP[
                                        'listMedications'
                                    ]
                                }
                            </strong>
                        </li>
                    </ul>
                </div>
                <div className="col-md-5">
                    <ul className="no-bullet list-align-left">
                        <li>
                            Specialist Name:{' '}
                            <strong>
                                {
                                    enrollment.enrolling.prequalSNP[
                                        'specialistName'
                                    ]
                                }
                            </strong>
                        </li>
                        <li>
                            Phone Number:{' '}
                            <strong>
                                {
                                    enrollment.enrolling.prequalSNP[
                                        'specialistPhone'
                                    ]
                                }
                            </strong>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

ReviewSNP = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(ReviewSNP);

ReviewSNP = connect((state) => {
    return getFormValues('wizard')(state) || {};
})(ReviewSNP);

export default ReviewSNP;
