import React, { useContext, useEffect, useState } from 'react';
import { isEmpty, get } from 'lodash';
import { getAllResourceUrls, getFeatureFlags } from '../api/config';
import { RESOURCE_TYPE } from '../constants/identifiers';
import './config.css';
import { Spinner, Alert } from 'react-bootstrap';

const FeatureFlagContext = React.createContext();

const useFeatureFlagContext = () => {
    const featureList = useContext(FeatureFlagContext);

    return featureList;
};

const ConfigContext = React.createContext();

const useConfigContext = () => {
    const configURLs = useContext(ConfigContext);
    return {
        PLAN_DOCUMENTS_URL: configURLs[RESOURCE_TYPE.MEMBER_RESOURCE],
        BROKER_PORTAL_URL: configURLs[RESOURCE_TYPE.BROKER_PORTAL],
        PROVIDER_SEARCH_URL: configURLs[RESOURCE_TYPE.PROVIDER_SEARCH],
        GOLDEN_COMM_SHOPPING_URL:
            configURLs[RESOURCE_TYPE.GOLDEN_COMM_SHOPPING],
    };
};

const WithConfigContext = ({ children }) => {
    const [configURLs, setConfigURLs] = useState();
    const [flagValues, setFeatureFlagValues] = useState({});

    useEffect(() => {
        getAllResourceUrls()
            .then((urlSet) => {
                setConfigURLs(urlSet);
            })
            .catch((err) => {
                throw err;
            });

        const processResourceService = getAllResourceUrls()
            .then((urlSet) => {
                setConfigURLs(urlSet);
            })
            .catch((err) => {
                throw err;
            });
        const processFlagService = getFeatureFlags()
            .then((urlSet = []) => {
                const flags = {};
                urlSet.forEach(({ name, isActive }) => {
                    flags[name] = isActive;
                });
                setFeatureFlagValues(flags);
            })
            .catch((err) => {
                throw err;
            });
        Promise.all([processResourceService, processFlagService]);
    }, []);
    if (isEmpty(configURLs) || isEmpty(flagValues)) {
        return (
            <div className="pre-loading" data-testid="configSpinner">
                <Spinner animation="border" variant="secondary" />
            </div>
        );
    } else if (get(configURLs, 'status', 200) >= 400) {
        return (
            <div className="pre-loading text-left">
                <Alert variant="danger" className="mt-5">
                    There was a network error.
                    <br />
                    Please try refreshing or contact the system administrator.
                </Alert>
            </div>
        );
    }
    return (
        <ConfigContext.Provider value={configURLs}>
            <FeatureFlagContext.Provider value={flagValues}>
                {children}
            </FeatureFlagContext.Provider>
        </ConfigContext.Provider>
    );
};

export {
    ConfigContext,
    WithConfigContext,
    useConfigContext,
    useFeatureFlagContext,
};
