import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import LinkButton from '../common/LinkButton';
import { PAGES } from '../../constants/enum';
import { formatCost, formatPhysicianName } from '../../util/format';
import { get, isEmpty } from 'lodash';
import AttentionModal from '../../components/common/AttentionModal/AttentionModal';
import { RESET_ENROLLMENT_WARNING } from '../../constants/alertMessages';
import { useConfigContext } from 'contexts/config';

const SelectionStatusView = ({
    enrollment,
    navigatePage,
    bordered,
    hasGoldenCommmOrigin,
}) => {
    const [showModal, setShowModal] = useState(false);
    const { GOLDEN_COMM_SHOPPING_URL } = useConfigContext();
    const physician = get(enrollment, 'selectPlan.physicianInfo', {});

    const toggleModal = () => {
        setShowModal(!showModal);
    };
    const alertToRestoreProcess = () => {
        setShowModal(true);
    };
    return (
        <div className="plan-selection">
            <AttentionModal
                toggleModal={toggleModal}
                showModal={showModal}
                bodyComponent={RESET_ENROLLMENT_WARNING}
                buttonsComponent={
                    <div>
                        <Button
                            className="attention-no-btn"
                            onClick={toggleModal}
                        >
                            NO
                        </Button>
                        <Button
                            className="attention-yes-btn"
                            onClick={() => {
                                toggleModal();
                                window.location.href = GOLDEN_COMM_SHOPPING_URL;
                            }}
                        >
                            YES
                        </Button>
                    </div>
                }
            />

            <div className="selection-pretext">Enrolling Into</div>
            <div className="selection">
                <ul className="selection-list">
                    <li className="plan-names pt-2">
                        <strong>
                            {enrollment.selectPlan.planSummary.planName} - Plan
                            Year {enrollment.findPlan.coverageYear}
                        </strong>
                        -{' '}
                        {formatCost(
                            enrollment.selectPlan.planSummary.premiumAmount
                        )}
                        {'  '}
                        <LinkButton
                            className="edit-selection"
                            onClick={() =>
                                hasGoldenCommmOrigin
                                    ? alertToRestoreProcess()
                                    : navigatePage(PAGES.SELECT_PLAN)
                            }
                            aria-label="Edit plan selection"
                        >
                            <FontAwesomeIcon
                                icon={faPencilAlt}
                                aria-label="Click to edit plan selection"
                            />
                        </LinkButton>
                    </li>
                    {!isEmpty(enrollment.selectPlan.physicianInfo) && (
                        <p className="additional-info physician-info">
                            <strong>Physician Name:</strong>{' '}
                            {formatPhysicianName(physician)}
                            <br />
                            <strong>Provider ID: </strong> {physician.provID}
                            <br />
                            <strong>Medical Group: </strong> {physician.ipaName}
                        </p>
                    )}
                    {enrollment.selectPlan.optionalPlanName && (
                        <>
                            <li className="plan-names pt-2">
                                <strong>
                                    {enrollment.selectPlan.optionalPlanName}
                                </strong>{' '}
                                -{' '}
                                {formatCost(
                                    enrollment.selectPlan.optionalPlanSummary
                                        .premiumAmount
                                )}
                                {'  '}
                                <LinkButton
                                    className="edit-selection"
                                    onClick={() =>
                                        navigatePage(
                                            PAGES.OPTIONAL_BENEFITS_PLAN
                                        )
                                    }
                                    aria-label="Edit optional benefit selection"
                                >
                                    <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        aria-label="Click to edit optional benefit selection"
                                    />
                                </LinkButton>
                            </li>
                            <p className="additional-info">
                                <strong>Total Monthly Premium:</strong>{' '}
                                {formatCost(
                                    parseFloat(
                                        enrollment.selectPlan
                                            .optionalPlanSummary.premiumAmount
                                    ) +
                                        parseFloat(
                                            enrollment.selectPlan.planSummary
                                                .premiumAmount
                                        )
                                )}
                            </p>
                        </>
                    )}
                </ul>
            </div>
        </div>
    );
};

const SelectionStatus = connect((state) => {
    return getFormValues('wizard')(state) || {};
})(SelectionStatusView);
export default SelectionStatus;
