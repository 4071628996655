import React, { useState, useCallback } from 'react';
import { Field, reduxForm } from 'redux-form';
import { RenderField, RenderItemSelector } from '../../actions/renderField';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tooltip as TooltipBootstrap, OverlayTrigger } from 'react-bootstrap';
import { normalizeDigit } from '../../util/normalize';
import { LENGTH_RESTRICTIONS } from '../../constants/input';
import states from '../../../../constants/states';
import './AddressForm.scss';
import FieldTrimmed from '../../actions/FieldTrimmed';
import CustomIcon from '../../../Common/Icon/CustomIcon';

const ToolTip = ({
    prefix,
    className,
    show,
    popoverMessage,
    iconLabel,
    ...rest
}) => {
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = useCallback(() => {
        setHovered(true);
    }, []);
    const handleMouseLeave = useCallback(() => {
        setHovered(false);
    }, []);

    if (!show) {
        return null;
    }
    return (
        <div
            id={`${prefix}-icon-popover-target`}
            className={classnames('tooltip-with-icon', className)}
        >
            <OverlayTrigger
                placement="top"
                overlay={
                    <TooltipBootstrap id={`address-tooltip-top`} {...rest}>
                        {popoverMessage}
                    </TooltipBootstrap>
                }
                onEnter={handleMouseEnter}
                onExited={handleMouseLeave}
            >
                <span data-testid="TooltipTrigger">
                    {hovered ? (
                        <CustomIcon
                            icon="question-outline"
                            aria-label={iconLabel}
                        />
                    ) : (
                        <CustomIcon
                            icon="question-solid"
                            aria-label={iconLabel}
                        />
                    )}
                </span>
            </OverlayTrigger>
        </div>
    );
};

const AddressForm = ({
    group,
    subGroup,
    subForm,
    disabled,
    zipcodeDisable,
    zipcodePopoverMessage,
    stateDisable,
    statePopoverMessage,
    stateList = states,
    includeSuiteApt,
    allowPOBox,
}) => {
    const [showZipPopover, setShowZipPopover] = useState(false);
    const [showStatePopover, setShowStatePopover] = useState(false);

    return (
        <div className="row address-form">
            <div className="col-lg-3">
                <FieldTrimmed
                    name={`enrollment[${group}][${subGroup}][${subForm}AddressLine1]`}
                    type="text"
                    required
                    charLimit={LENGTH_RESTRICTIONS.ADDRESS_LINE}
                    component={RenderField}
                    label={includeSuiteApt ? 'Address' : 'Address Line 1'}
                    disabled={disabled}
                />
                {!allowPOBox && (
                    <p className="helper-text">Do not enter a P.O. Box</p>
                )}
            </div>
            <div className="col-lg-3">
                <FieldTrimmed
                    name={`enrollment[${group}][${subGroup}][${subForm}AddressLine2]`}
                    type="text"
                    charLimit={LENGTH_RESTRICTIONS.SUITE_APT}
                    component={RenderField}
                    label={includeSuiteApt ? 'Suite, Apt' : 'Address Line 2'}
                    disabled={disabled}
                />
            </div>
            <div className="col-lg-3">
                <FieldTrimmed
                    name={`enrollment[${group}][${subGroup}][${subForm}City]`}
                    type="text"
                    required
                    charLimit={LENGTH_RESTRICTIONS.CITY}
                    component={RenderField}
                    label="City"
                    disabled={disabled}
                />
            </div>
            <div className="col-lg-3 state-field">
                <Field
                    name={`enrollment[${group}][${subGroup}][${subForm}State]`}
                    type="select"
                    required
                    list={[
                        ...Object.values(stateList).map(
                            (stateInfo) => stateInfo.short
                        ),
                    ]}
                    component={RenderItemSelector}
                    label="State"
                    disabled={stateDisable || disabled}
                    addPlaceholder
                    tooltip={
                        <ToolTip
                            className="state-tooltip"
                            iconLabel="Click for more information about state entered"
                            popoverMessage={statePopoverMessage}
                            show={stateDisable}
                            prefix="state"
                        />
                    }
                />
            </div>
            <div className="col-lg-3 zipcode-field">
                <FieldTrimmed
                    name={`enrollment[${group}][${subGroup}][${subForm}ZipCode]`}
                    type="text"
                    required
                    charLimit={LENGTH_RESTRICTIONS.ZIPCODE}
                    component={RenderField}
                    normalize={normalizeDigit}
                    label="Zip Code"
                    disabled={zipcodeDisable || disabled}
                    tooltip={
                        <ToolTip
                            className="zipcode-tooltip"
                            iconLabel="Click for more information about zip code entered"
                            popoverMessage={zipcodePopoverMessage}
                            show={zipcodeDisable}
                            prefix="zipcode"
                        />
                    }
                />
            </div>
        </div>
    );
};
AddressForm.propTypes = {
    subForm: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired,
    subGroup: PropTypes.string.isRequired,
    onlyAllowedStatesAHC: PropTypes.bool,
    includeSuiteApt: PropTypes.bool,
};
AddressForm.defaultProps = {
    pageNum: 1,
    subPageNum: 1,
    subForm: '',
    disabled: false,
    zipcodeDisable: false,
    zipcodePopoverMessage: '',
    stateDisable: false,
    statePopoverMessage: '',
    stateList: states,
    allowPOBox: true,
};
const AddressFormWithRedux = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(AddressForm);

export default AddressFormWithRedux;
