import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Route, useHistory } from 'react-router-dom';
import { useFeatureFlagContext } from 'contexts/config';

const ConditionalRoute = ({
    defaultPath,
    component: Component,
    flagId,
    ...rest
}) => {
    const ConditionalComponent = (props) => {
        const history = useHistory();
        const featureFlags = useFeatureFlagContext();

        const meetsCondition = isEmpty(flagId) || featureFlags[flagId];

        if (!meetsCondition) {
            history.push(defaultPath);
        }
        return <Component {...props} />;
    };
    return <Route {...rest} component={ConditionalComponent} />;
};

ConditionalRoute.propTypes = {
    defaultPath: PropTypes.string,
};
ConditionalRoute.defaultProps = {
    defaultPath: '/',
    component: () => null,
};

export default ConditionalRoute;
