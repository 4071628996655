import { useEffect } from 'react';
import {
    RESPONSE,
    ENROLLER_TYPE,
} from '../../../../../../../constants/identifiers';
import { getCMSEligibilityDetail } from '../../../../../../../api/enrollment';

const clearCMSForm = (change) => {
    change('enrollment.enrolling.clientInfo.medicareEligibilityDatePartA', '');
    change('enrollment.enrolling.clientInfo.medicareEligibilityDatePartB', '');
    change('enrollment.enrolling.clientInfo.firstName', '');
    change('enrollment.enrolling.clientInfo.middleInitial', '');
    change('enrollment.enrolling.clientInfo.gender', '');
    change('enrollment.enrolling.clientInfo.primaryLanguage', '');
    change('enrollment.enrolling.clientInfo.initialMethodOfContact', '');
};

const mapCMSInfo = (CMS, change, setVerifyMessage) => {
    if (CMS.foundMedID === RESPONSE.NO) {
        setVerifyMessage([
            "We're unable to identify this Medicare ID.",
            'Please enter Part A and Part B eligibility dates and proceed with your application.',
        ]);
        return;
    } else if (CMS.foundNameOrDOB === RESPONSE.NO) {
        setVerifyMessage(['Error: Unable to Identify Name or Date of Birth']);
        return;
    }
    change('enrollment.enrolling.clientInfo.firstName', CMS.firstName);
    change('enrollment.enrolling.clientInfo.lastName', CMS.lastName);
    change('enrollment.enrolling.clientInfo.middleInitial', CMS.middleInitial);
    change('enrollment.enrolling.clientInfo.dateOfBirth', CMS.birthDate);
    change(
        'enrollment.enrolling.clientInfo.medicareEligibilityDatePartA',
        CMS.partAEntitlementDate
    );
    change(
        'enrollment.enrolling.clientInfo.medicareEligibilityDatePartB',
        CMS.partBEntitlementDate
    );
    change('enrollment.enrolling.clientInfo.gender', CMS.genderCd);
    setVerifyMessage(['Success: Applicant Verified']);
};
const verifyCMSEligibility = async (
    props,
    setLoadingState,
    setVerifyMessage
) => {
    const { enrollment, change } = props;
    setLoadingState(true);
    clearCMSForm(change);

    const response = await getCMSEligibilityDetail(
        enrollment.enrolling.clientInfo.medicareID,
        enrollment.enrolling.clientInfo.lastName,
        enrollment.enrolling.clientInfo.dateOfBirth
    )
        .then((result) => {
            setLoadingState(false);
            return result;
        })
        .catch((e) => {
            setLoadingState(false);
            return;
        });
    if (response && response.success) {
        mapCMSInfo(response.cmsEligibilityData, change, setVerifyMessage);
    } else {
        setVerifyMessage([
            'Error: Unable to Verify. Have you entered all of Medicare ID #, Last Name, and Date of Birth?',
        ]);
    }
};

const useCheckEnrollerTypeApplicant = (change, enrollerType) => {
    useEffect(() => {
        if (enrollerType == ENROLLER_TYPE.APPLICANT) {
            change(
                'enrollment.enrolling.clientInfo.representativeFirstName',
                null
            );
            change(
                'enrollment.enrolling.clientInfo.representativeMiddleInitial',
                null
            );
            change(
                'enrollment.enrolling.clientInfo.representativeLastName',
                null
            );
            change('enrollment.enrolling.clientInfo.representativePhone', null);
            change(
                'enrollment.enrolling.clientInfo.representativeAddressLine1',
                null
            );
            change(
                'enrollment.enrolling.clientInfo.representativeAddressLine2',
                null
            );
            change(
                'enrollment.enrolling.clientInfo.representativeSuitApt',
                null
            );
            change('enrollment.enrolling.clientInfo.representativeCity', null);
            change('enrollment.enrolling.clientInfo.representativeState', null);
            change(
                'enrollment.enrolling.clientInfo.representativeZipCode',
                null
            );
            change(
                'enrollment.enrolling.clientInfo.representativeRelationship',
                null
            );
        }
    }, [enrollerType]);
};

export { verifyCMSEligibility, useCheckEnrollerTypeApplicant };
