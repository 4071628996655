import React, { useState, useEffect, useRef } from 'react';
import {
    Modal,
    Form,
    FormControl,
    Card,
    InputGroup,
    Row,
    Col,
    ListGroup,
    Button,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { isEmpty, reduce } from 'lodash';
import './providerListModal.scss';
import { formatPhysicianName } from '../../../../util/format';
import { normalizePhone } from '../../../../util/normalize';
import cn from 'classnames';
import { get } from 'lodash';

const isDateInTheFuture = (dateString) => {
    const parsedDate = moment(dateString);
    if (parsedDate.isValid()) {
        return moment().diff(parsedDate) < 0;
    } else {
        return false;
    }
};

const MSG_ACCEPTING_NEW_PATIENTS = 'Accepting New (All) Patients';
const MSG_NOT_ACCEPTING_NEW_PATIENTS = 'Accepting Existing Patients Only';

const Address = ({
    address1,
    address2,
    city,
    state,
    zip,
    isAcceptingNewPatients,
    mobileProvDisclaimer,
    phone,
}) => {
    if (!isEmpty(mobileProvDisclaimer)) {
        return (
            <Row className="address-wrapper">
                <Col className="address d-flex flex-column">
                    <div>{mobileProvDisclaimer}</div>

                    <ul className="px-0 mx-0 no-bullet">
                        <li>
                            {isAcceptingNewPatients.toLowerCase() === 'yes'
                                ? MSG_ACCEPTING_NEW_PATIENTS
                                : MSG_NOT_ACCEPTING_NEW_PATIENTS}
                        </li>
                    </ul>
                </Col>
            </Row>
        );
    }
    return (
        <div className="address-wrapper">
            <Col md="6" className="address d-flex flex-column mb-2">
                <strong>Address:</strong>
                <span>{address1}</span>
                {!isEmpty(address2) && <span>{address2}</span>}
                <span>
                    {city}, {state} {zip}
                </span>
            </Col>
            <Col md="6" className="address">
                <strong>Phone: </strong>
                <span>{normalizePhone(phone)}</span>
            </Col>
            <Col
                md="7"
                className={cn({
                    accepting_new:
                        isAcceptingNewPatients.toLowerCase() === 'yes',
                    accepting_existing:
                        isAcceptingNewPatients.toLowerCase() === 'no',
                })}
            >
                <ul className="px-0 mx-0 no-bullet">
                    <li>
                        {isAcceptingNewPatients.toLowerCase() === 'yes'
                            ? MSG_ACCEPTING_NEW_PATIENTS
                            : MSG_NOT_ACCEPTING_NEW_PATIENTS}
                    </li>
                </ul>
            </Col>
        </div>
    );
};

const ProviderItem = ({
    providerID,
    addresses,
    ipa,
    effectiveDate,
    onSelect,
    canSelect,
    physicianInfo,
    enrollment,
}) => {
    const checkedGroup = get(enrollment, 'selectPlan.physicianInfo.provID');
    return (
        <Card className="provider-item-card">
            <Card.Header>
                {canSelect && (
                    <Form.Check
                        name="medicalGroup"
                        type="radio"
                        data-testid={`group_${providerID}`}
                        label="Select"
                        checked={
                            providerID ===
                            get(enrollment, 'selectPlan.physicianInfo.provID')
                        }
                        value={providerID}
                        onChange={() => onSelect(providerID, physicianInfo)}
                    />
                )}
                <Card.Title>{ipa.desc}</Card.Title>
                <p>
                    <strong>Provider ID: </strong>
                    {providerID}
                </p>
            </Card.Header>
            <Card.Body>
                <div className="physician">
                    <ul className="text-left px-0 mx-0 no-bullet">
                        {isDateInTheFuture(effectiveDate) && (
                            <li>
                                <strong>Joining Network Date: </strong>
                                <span className="date">{effectiveDate}</span>
                            </li>
                        )}
                        <li>
                            <strong>Location(s): </strong>
                            <ListGroup variant="flush">
                                {addresses.map((address, idx) => (
                                    <ListGroup.Item key={`address_${idx}`}>
                                        <Address {...address} />
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </li>
                    </ul>
                </div>
            </Card.Body>
        </Card>
    );
};

const SearchFilter = ({ runSearch }) => {
    const [searchText, setSearchText] = useState('');
    const [filterView, setFilterView] = useState({
        newPatients: false,
        existingPatients: false,
    });
    const handleChange = (e) => {
        setSearchText(e.target.value);
        runSearch({ term: e.target.value, filterView });
    };
    const handleAcceptingCheck = (e) => {
        setFilterView({ ...filterView, newPatients: e.target.checked });
        runSearch({
            term: searchText,
            filterView: { ...filterView, newPatients: e.target.checked },
        });
    };
    const handleExistingCheck = (e) => {
        setFilterView({ ...filterView, existingPatients: e.target.checked });
        runSearch({
            term: searchText,
            filterView: { ...filterView, existingPatients: e.target.checked },
        });
    };
    return (
        <Form className="search-filter">
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                        <Button
                            aria-label="Search Button"
                            type="submit"
                            onClick={(e) => {
                                e.preventDefault();
                                runSearch({
                                    term: searchText,
                                    filterView,
                                });
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faSearch}
                                aria-label="Search icon for button"
                            />
                        </Button>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    type="search"
                    aria-label="Search for address, group name"
                    placeholder="Search for address, group name"
                    onChange={handleChange}
                    value={searchText}
                />
            </InputGroup>
            <div className="filter-checkboxes">
                <p>Filter by</p>
                <Row>
                    <Form.Check
                        type="checkbox"
                        id="accepting-check"
                        label={MSG_ACCEPTING_NEW_PATIENTS}
                        value={filterView.newPatients}
                        onChange={handleAcceptingCheck}
                    />
                    <Form.Check
                        type="checkbox"
                        id="existing-check"
                        label={MSG_NOT_ACCEPTING_NEW_PATIENTS}
                        value={filterView.existingPatients}
                        onChange={handleExistingCheck}
                    />
                </Row>
            </div>
        </Form>
    );
};

const ProviderListModal = ({
    showModal,
    handleClose,
    npi,
    name,
    physicianInfo,
    enrollment,
    change,
    canSelect,
}) => {
    const documentRootRef = useRef(document.getElementById('root'));
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [selectedIsClosedPanel, setSelectedIsClosedPanel] = useState(false);
    const [showClosedPanel, setShowClosedPanel] = useState(false);
    const [existingAttestation, setExistingAttestation] = useState(
        get(enrollment, 'selectPlan.closedPanelPCPAttestation') || false
    );
    const [disabledProceedButton, setDisabledProceedButton] = useState(true);
    const [isExistingPatient, setIsExistingPatient] = useState(false);
    const prevNPIRef = useRef();
    const ahRecords = physicianInfo.ahRecords;

    const medicalGroup = get(enrollment, 'selectPlan.physicianInfo.provID');

    useEffect(() => {
        if (!showModal && !isEmpty(filteredRecords)) {
            setFilteredRecords([]);
            prevNPIRef.current = null;
        }
    }, [showModal]);

    useEffect(() => {
        const selectedNPI = npi;
        if (showModal && prevNPIRef.current !== selectedNPI) {
            if (filteredRecords.length === 0) {
                resetResults();
            }
            prevNPIRef.current = selectedNPI;
        }
    }, [enrollment, showModal]);

    useEffect(() => {
        if (filteredRecords.length > 0) {
            setDisabledProceedButton(true);
            setShowClosedPanel(false);
            setIsExistingPatient(false);

            if (medicalGroup) {
                if (
                    filteredRecords.some(
                        (record) => record.providerID == medicalGroup
                    )
                ) {
                    if (selectedIsClosedPanel) {
                        setShowClosedPanel(true);
                        if (
                            existingAttestation &&
                            filteredRecords.some(
                                (record) => record.providerID == medicalGroup
                            )
                        ) {
                            setDisabledProceedButton(false);
                        } else {
                            setDisabledProceedButton(true);
                        }
                    } else {
                        const { addresses } = (
                            physicianInfo.ahRecords ?? []
                        ).find(({ providerID }) => providerID === medicalGroup);

                        const isClosedPanel =
                            addresses[0].isAcceptingNewPatients.toLowerCase() ===
                            'no';
                        setSelectedIsClosedPanel(isClosedPanel);
                        setShowClosedPanel(isClosedPanel);
                        if (
                            !isClosedPanel &&
                            physicianInfo?.npi ===
                                get(enrollment, 'selectPlan.physicianNPI')
                        ) {
                            setIsExistingPatient(
                                get(enrollment, 'selectPlan.isExistingPatient')
                            );
                        }
                        setDisabledProceedButton(
                            filteredRecords.every(
                                (record) => record.providerID != medicalGroup
                            )
                        );
                    }
                } else {
                    setExistingAttestation(false);
                }
            }
        }
    }, [
        selectedIsClosedPanel,
        medicalGroup,
        existingAttestation,
        filteredRecords,
    ]);

    const resetResults = () => {
        setFilteredRecords(ahRecords);
    };
    const handleHide = () => {
        resetResults();
        handleClose();
        change('enrollment.selectPlan.physicianInfo', null);
        change('enrollment.selectPlan.physicianNPI', null);
        change('enrollment.selectPlan.isExistingPatient', null);
        change('enrollment.selectPlan.closedPanelPCPAttestation', null);
    };
    const onSelectPhysician = (selectedProviderID, physicianInfo) => {
        const { addresses, ipa } = physicianInfo.ahRecords.find(
            ({ providerID }) => providerID === selectedProviderID
        );

        const isClosedPanel =
            addresses[0].isAcceptingNewPatients.toLowerCase() === 'no';

        change(
            'enrollment.selectPlan.physicianInfo.provID',
            selectedProviderID
        );
        setSelectedIsClosedPanel(isClosedPanel);
        setShowClosedPanel(isClosedPanel);
        if (!isClosedPanel) {
            setExistingAttestation(false);
        }
    };
    const onProceed = (selectedProviderID, physicianInfo) => {
        const { addresses, ipa } = (physicianInfo.ahRecords ?? []).find(
            ({ providerID }) => {
                return providerID === selectedProviderID;
            }
        );
        const { phone } = addresses[0];
        const ipa_desc = ipa.desc;
        const ipa_id = ipa.id;
        const { firstName, middleName, lastName } = physicianInfo.nppes;
        change('enrollment.selectPlan.physicianInfo', physicianInfo);
        change('enrollment.selectPlan.physicianInfo.firstName', firstName);
        change('enrollment.selectPlan.physicianInfo.mi', middleName);
        change('enrollment.selectPlan.physicianInfo.lastName', lastName);

        change('enrollment.selectPlan.physicianInfo.ipaId', ipa_id);
        change('enrollment.selectPlan.physicianInfo.ipaName', ipa_desc);
        change('enrollment.selectPlan.physicianInfo.phone', phone);
        change(
            'enrollment.selectPlan.physicianInfo.provID',
            selectedProviderID
        );
        change(
            'enrollment.selectPlan.closedPanelPCPAttestation',
            existingAttestation
        );
        change('enrollment.selectPlan.isExistingPatient', isExistingPatient);
        handleClose(selectedProviderID);
    };
    const handleSearch = ({ term, filterView }) => {
        const parsedTerm = term.toLowerCase();
        const filtered = ahRecords.filter(({ addresses, ipa }) => {
            const hasAnyMatchingAddressText = addresses.some(
                ({ standardized }) =>
                    reduce(
                        standardized,
                        (cumu, value) => {
                            return `${cumu} ${value}`;
                        },
                        ''
                    )
                        .toLowerCase()
                        .indexOf(parsedTerm) > -1
            );
            const desc = ipa.desc;
            const filterToReturn = () => {
                if (filterView.newPatients && !filterView.existingPatients) {
                    return addresses.some(
                        ({ isAcceptingNewPatients }) =>
                            isAcceptingNewPatients.toLowerCase() === 'yes'
                    );
                }
                if (filterView.existingPatients && !filterView.newPatients) {
                    return addresses.some(
                        ({ isAcceptingNewPatients }) =>
                            isAcceptingNewPatients.toLowerCase() === 'no'
                    );
                }

                return true;
            };

            return (
                (desc.toLowerCase().indexOf(parsedTerm) > -1 ||
                    hasAnyMatchingAddressText) &&
                filterToReturn()
            );
        });
        setFilteredRecords(filtered);
    };
    return (
        <Modal
            show={showModal}
            onHide={handleHide}
            className="provider-list-modal"
            data-testid={`modal_${npi}`}
            scrollable
            backdrop="static"
            container={documentRootRef}
        >
            <Modal.Header closeButton>
                <div className="sections">
                    <div className="section">
                        <h4>Medical Groups for:</h4>
                        <h3 className="name">
                            {formatPhysicianName(physicianInfo)}
                        </h3>
                    </div>
                    <div className="section">
                        <SearchFilter runSearch={handleSearch} />
                    </div>
                    <h5 className="mt-1">
                        Number of Medical Groups: {filteredRecords.length}
                    </h5>
                    {filteredRecords.length <= 0 ? (
                        <p className="mb-0">
                            There are no medical groups matching this criteria.
                            Please try again or go back to the search results.
                        </p>
                    ) : (
                        <p>You must select one group to continue.</p>
                    )}
                </div>
            </Modal.Header>
            <Modal.Body className="pb-0">
                <div className="results">
                    {filteredRecords.map(
                        (
                            { providerID, addresses, ipa, effectiveDate },
                            idx
                        ) => {
                            return (
                                <ProviderItem
                                    providerID={providerID}
                                    addresses={addresses}
                                    ipa={ipa}
                                    effectiveDate={effectiveDate}
                                    onSelect={onSelectPhysician}
                                    key={`provider_item_${idx}`}
                                    canSelect={canSelect}
                                    physicianInfo={physicianInfo}
                                    enrollment={enrollment}
                                />
                            );
                        }
                    )}
                </div>
                {showClosedPanel && canSelect && (
                    <Card className="closed-panel-card">
                        <Card.Header>
                            <Card.Title>
                                <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    color="#F89C1D"
                                />
                                {'    '}
                                Closed Panel
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <div>
                                <p>
                                    The Primary Care Provider (PCP) you have
                                    selected is Accepting Existing Patients
                                    Only. If you are not an existing patient,
                                    please select a PCP that's Accepting New
                                    (All) Patients.
                                </p>
                                <Row className="px-4">
                                    <Form.Check
                                        name="existingAttestation"
                                        type="checkbox"
                                        id="closed-panel-check"
                                        label={
                                            'I am an existing patient of the selected PCP'
                                        }
                                        checked={existingAttestation}
                                        onChange={(e) =>
                                            setExistingAttestation(
                                                e.target.checked
                                            )
                                        }
                                    />
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                )}
            </Modal.Body>
            {canSelect && (
                <Modal.Footer className="provider-modal-footer">
                    {medicalGroup &&
                        physicianInfo?.ahRecords.some(
                            (record) => record.providerID === medicalGroup
                        ) &&
                        !selectedIsClosedPanel && (
                            <Row className="px-4">
                                <Form.Check
                                    name="isExistingPatient"
                                    type="checkbox"
                                    id="existing-patient-check"
                                    label={
                                        'I am an existing patient of the selected PCP'
                                    }
                                    checked={isExistingPatient}
                                    onChange={(e) =>
                                        setIsExistingPatient(e.target.checked)
                                    }
                                />
                            </Row>
                        )}
                    <Button
                        className="provider-modal-button"
                        variant="outline-secondary"
                        onClick={handleHide}
                    >
                        Cancel
                    </Button>{' '}
                    {filteredRecords.length >= 1 && (
                        <Button
                            className="provider-modal-button"
                            variant="primary"
                            disabled={disabledProceedButton}
                            onClick={() => {
                                onProceed(
                                    get(
                                        enrollment,
                                        'selectPlan.physicianInfo.provID'
                                    ),
                                    physicianInfo
                                );
                            }}
                        >
                            Proceed
                        </Button>
                    )}
                </Modal.Footer>
            )}
        </Modal>
    );
};
const ProviderListModalWithForm = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(
    connect((state) => {
        return getFormValues('wizard')(state) || {};
    })(ProviderListModal)
);

export { ProviderListModalWithForm as default, ProviderListModal };
