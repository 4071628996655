import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import validate from '../../../actions/validate';
import { formatPhysicianName } from '../../../../util/format';

let ReviewPCP = (props) => {
    let physicianProps = props.enrollment.selectPlan.physicianInfo;
    return (
        <div className="row">
            <div className="col-md-5">
                <ul className="no-bullet list-align-left">
                    <li>
                        PCP:{' '}
                        <strong>{formatPhysicianName(physicianProps)}</strong>
                    </li>
                    <li>
                        Phone Number:{' '}
                        <strong>
                            {physicianProps ? physicianProps['phone'] : null}
                        </strong>
                    </li>
                </ul>
            </div>
            <div className="col-md-5">
                <ul className="no-bullet list-align-left">
                    <li>
                        Medical Group: <br />
                        <strong>
                            {physicianProps ? physicianProps['ipaName'] : null}
                        </strong>
                    </li>
                    <li>
                        ID:{' '}
                        <strong>
                            {physicianProps ? physicianProps['provID'] : null}
                        </strong>
                    </li>
                </ul>
            </div>
        </div>
    );
};

ReviewPCP = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(ReviewPCP);

ReviewPCP = connect((state) => {
    return getFormValues('wizard')(state) || {};
})(ReviewPCP);

export default ReviewPCP;
