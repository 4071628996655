export const DIABETES_SURVEY = {
    diabetes1:
        'Have you been told by a doctor that you have diabetes (too much sugar in the blood or urine)?',
    diabetes2:
        'Have you ever been prescribed or are you taking insulin or an oral medication that is supposed to lower the sugar in your blood?',
};
export const CARDIOVASCULAR_SURVEY = {
    cardiovascular1:
        'Have you ever been told by a doctor that you have coronary artery disease, poor circulation due to hardening of the arteries or poor veins?',
    cardiovascular2:
        'Have you ever had a heart attack or been admitted to the hospital for Angina(chest pain)?',
};
export const HEART_FAILURE_SURVEY = {
    chronicHeart1:
        'Have you ever been told by a doctor that you have heart failure (weak heart)?',
    chronicHeart2:
        'Have you ever had problems with fluid in your lungs and swelling in your legs in the past, accompanied by shortness of breath, due to a heart problem?',
};

export const ESRD_SURVEY = {
    esrd1: 'Are you currently undergoing Peritoneal dialysis?',
    esrd2: 'Are you currently undergoing Hemodialysis?',
};
