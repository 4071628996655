import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, Field, reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { noop, isEmpty } from 'lodash';
import { Tab, Tabs, Accordion, Card } from 'react-bootstrap';
import AccessCard from '../../../../../assets/img/access-card.png';
import SubviewProvider from './Subviews/SubviewProvider';
import SubviewPlanDetails from './Subviews/SubviewPlanDetails';
import { RenderCheckbox } from '../../../actions/renderField';
import { getProviderDetails } from '../../../../../api/enrollment';
import CostSummary from './Subviews/CostSummary';
import './planPreview.scss';
import { formatIdString } from '../../../util/format';

let PlanPreview = ({
    comparableView,
    contractCode,
    pbp,
    title,
    removePlan,
    handleTitleClick,
    monthlyPremium,
    pcpCopay,
    specialistCopay,
    outOfPocketMax,
    benefits,
    planSummary,
    benefitSummaryENG,
    benefitSummarySPA,
    benefitSummaryCHN,
    benefitSummaryVIET,
    benefitSummaryKOR,
    showCardBenefits = false,
}) => {
    const renderClickableAction = () => {
        if (comparableView) {
            return (
                <div className="col-xs-2 pl-4">
                    <FontAwesomeIcon
                        style={{
                            cursor: 'pointer',
                        }}
                        aria-label="Remove  Plan from Compare Plans list"
                        icon={faTimes}
                        onClick={async () => {
                            removePlan(`${title}`);
                        }}
                    />
                </div>
            );
        }
        return (
            <div className="col-xs-2 compare-button">
                <Field
                    name={`comparePlans[${title}]`}
                    label="Compare Plans"
                    type="checkbox"
                    ariaLabel={`Compare with ${title}`}
                    component={RenderCheckbox}
                />
            </div>
        );
    };

    return (
        <div className="text-left plan-preview">
            <CostSummary
                renderClickableAction={renderClickableAction}
                handleTitleClick={handleTitleClick}
                title={title}
                monthlyPremium={monthlyPremium}
                pcpCopay={pcpCopay}
                specialistCopay={specialistCopay}
                outOfPocketMax={outOfPocketMax}
            />

            {showCardBenefits && (
                <div className="row ml-auto pb-4 benefits-row">
                    <div className="col-5">
                        <ul
                            className="mx-0 px-0 text-muted no-bullet"
                            style={{ fontSize: '75%', fontWeight: 'bold' }}
                        >
                            {benefits.map((item, index) => {
                                return (
                                    <li
                                        className="benefits"
                                        key={`benefits_${index}`}
                                    >
                                        {item}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="col">
                        <img
                            className="sample-card mx-0 px-0"
                            src={AccessCard}
                            alt="access_card"
                        />
                    </div>
                </div>
            )}

            <div>
                <Tabs
                    defaultActiveKey="planDetails"
                    id={`planTabs-${formatIdString(title)}`}
                    className="h5 bold tab-view"
                >
                    <Tab eventKey="planDetails" title="Plan Details">
                        <div className="tab-view">
                            <SubviewPlanDetails
                                displayView="tab"
                                title={formatIdString(title)}
                                details={planSummary}
                                comparableView={comparableView}
                                benefitSummaryENG={benefitSummaryENG}
                                benefitSummarySPA={benefitSummarySPA}
                                benefitSummaryCHN={benefitSummaryCHN}
                                benefitSummaryVIET={benefitSummaryVIET}
                                benefitSummaryKOR={benefitSummaryKOR}
                            />
                        </div>
                    </Tab>
                    <Tab eventKey="planProviders" title="Plan Providers">
                        <div className="tab-view">
                            <SubviewProvider
                                displayView="tab"
                                pbp={pbp}
                                contractCode={contractCode}
                            />
                        </div>
                    </Tab>
                </Tabs>

                <Accordion className="accordion-view clickable">
                    <Card>
                        <Accordion.Toggle
                            as={Card.Header}
                            variant="link"
                            eventKey="0"
                        >
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="accordion-text">Plan Details</p>
                                <span>
                                    <FontAwesomeIcon
                                        icon={faChevronDown}
                                        title="chevron-down"
                                    />
                                </span>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <SubviewPlanDetails
                                    displayView="accordian"
                                    title={formatIdString(title)}
                                    details={planSummary}
                                    comparableView={comparableView}
                                    benefitSummaryENG={benefitSummaryENG}
                                    benefitSummarySPA={benefitSummarySPA}
                                    benefitSummaryCHN={benefitSummaryCHN}
                                    benefitSummaryVIET={benefitSummaryVIET}
                                    benefitSummaryKOR={benefitSummaryKOR}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle
                            as={Card.Header}
                            className="h5 bold"
                            variant="link"
                            eventKey="1"
                        >
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="accordion-text">Plan Providers</p>
                                <span>
                                    <FontAwesomeIcon
                                        icon={faChevronDown}
                                        title="chevron-down"
                                    />
                                </span>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <SubviewProvider
                                    displayView="accordian"
                                    pbp={pbp}
                                    contractCode={contractCode}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        </div>
    );
};

PlanPreview.propTypes = {
    title: PropTypes.string,
    monthlyPremium: PropTypes.number,
    pcpCopay: PropTypes.string,
    specialistCopay: PropTypes.string,
    outOfPocketMax: PropTypes.number,
    benefits: PropTypes.arrayOf(PropTypes.string),
    planSummary: PropTypes.arrayOf(PropTypes.object),
    viewMenu: PropTypes.bool,
    comparableView: PropTypes.bool,
    viewPCP: PropTypes.bool,
    handleTitleClick: PropTypes.func,
    benefitSummaryENG: PropTypes.string,
    benefitSummarySPA: PropTypes.string,
    benefitSummaryCHN: PropTypes.string,
    benefitSummaryVIET: PropTypes.string,
    benefitSummaryKOR: PropTypes.string,
};
PlanPreview.defaultProps = {
    handleTitleClick: noop,
};
PlanPreview = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(PlanPreview);

PlanPreview = connect((state) => {
    return getFormValues('wizard')(state);
})(PlanPreview);
export default PlanPreview;
