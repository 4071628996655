import { SEP_QUESTIONS } from './verification';
import {
    DIABETES_SURVEY,
    CARDIOVASCULAR_SURVEY,
    HEART_FAILURE_SURVEY,
} from './prequalification';

import { RECEIPT_PREF } from './enum';
import { isEmpty } from 'lodash';

const PREQUAL_LENGTH =
    Object.keys(DIABETES_SURVEY).length +
    Object.keys(CARDIOVASCULAR_SURVEY).length +
    Object.keys(HEART_FAILURE_SURVEY).length;

export const INITIAL_VALUES = {
    hasGoldenCommmOrigin: false,
    enrollment: {
        documents: [],
        findPlan: {},
        selectPlan: {
            physicianInfo: {},
            planSummary: {},
            optionalPlanSummary: {},
        },
        enrolling: {
            medicareAdvantageEligibility: {
                questions: Array.from(SEP_QUESTIONS, (e, index) => ({
                    questionNum: index + 1,
                    text: e.text,
                    response: false,
                    additionalQuestions: !isEmpty(e.additionalQuestions)
                        ? e.additionalQuestions.map(
                              (_, additionalQuestionIndex) => ({
                                  questionNum: additionalQuestionIndex + 1,
                              })
                          )
                        : null,
                })),
            },
            clientInfo: {
                // Initial Date set to first of the following month
                memberInformationDelivery: RECEIPT_PREF.NONE.toString(),
            },
            additionalInfo: {
                questions: [
                    // COVERAGE
                    {
                        questionNum: 1,
                        response: null,
                        additionalQuestions: Array.from(
                            { length: 3 },
                            (e, i) =>
                                new Object({
                                    questionNum: i + 1,
                                    response: null,
                                })
                        ),
                    },
                    // CHRONIC CONDITION
                    {
                        questionNum: 2,
                        response: null,
                    },
                    //ELLIGIBLE
                    {
                        questionNum: 3,
                        response: null,
                    },
                    // ENROLLED
                    {
                        questionNum: 4,
                        response: null,
                        additionalQuestions: [
                            { questionNum: 1, response: null },
                        ],
                    },
                ],
            },
            prequalSNP: {
                questions: Array.from(
                    { length: PREQUAL_LENGTH + 1 },
                    (e, i) =>
                        new Object({
                            questionNum: i + 1,
                            response: null,
                        })
                ).concat(
                    { questionNum: 17, response: null },
                    { questionNum: 18, response: null }
                ),
            },
        },
        review: {},
    },
};
